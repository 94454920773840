import React from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import Navbar from './navbar/navbar';
import Newsletter from './newsletter/newsletter';
import Footer from './footer/footer';
import ScrollUpButton from './scroll-up-button/scroll-up-button';
import InstagramShowcase from '../containers/instagram-showcase';
import ResetCss from './reset-css';
import { theme } from '../theme';
import CookieConsent from 'react-cookie-consent';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <Navbar />
        </Sticky>

        {children}

        {/* <InstagramShowcase /> */}
        {/* <Newsletter /> */}
        <Footer>
          {/* Copyright &copy; {new Date().getFullYear()} */}
          {/* <a href="https://redq.io/"> RedQ, Inc.</a> */}
        </Footer>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          enableDeclineButton={true}
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allows us to remember you. We use this information to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, please see our <a href="https://goodliferead.com/privacy" target="_blank">Privacy Policy</a>.
<br /><br />
If you decline, your information won't be tracked when you visit this website again. A single cookie will be used in your browser to remember your preference not to be tracked.
</CookieConsent>
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default Layout;
